import { createApp } from 'vue'
import App from './App.vue'

import router from './router';
import store from '@/store/index.js';

import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';

import '@/assets/css/common.scss'
import '@/assets/css/media.scss'
import '@/assets/css/reset.css'
const app = createApp(App)
app.use(ElementPlus,{locale})
app.use(router)
app.use(store)
app.mount('#app')
/**
 * 权限指令,对按钮权限的控制
 */
 app.directive('allow', {
    // 指令绑定元素挂载前
    beforeMount(el) {},
    // 指令绑定元素挂载后
    mounted(el, binding) {
        // 通过当前按钮，获取所在权限组的组名
        let name = binding.value[0];

        // 当前页面路径
        let path = router.currentRoute.value.path
        
        let all_available_menu = localStorage.getItem('all_available_menu')
        if(all_available_menu){
            all_available_menu = JSON.parse(all_available_menu)
        }
        if(all_available_menu && all_available_menu.length>0){
            for(let i in all_available_menu){
                if(all_available_menu[i].path === path){
                    let permission_menu = all_available_menu[i].meta.permission_menu
                    if(!permission_menu || !permission_menu.includes(name)){
                        // 不包含权限，隐藏
                        el.style.display = 'none';
                    }
                    break;
                }
            }
        }
    },
    // 指令绑定元素因为数据修改触发修改前
    beforeUpdate(el) {},
    // 指令绑定元素因为数据修改触发修改后
    updated(el) {},
    // 指令绑定元素销毁前
    beforeUnmount(el) {},
    // 指令绑定元素销毁后
    unmounted(el) {},
});