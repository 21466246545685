//设置用户信息
export const SET_USERDATA = 'SET_USERDATA'
//移除用户信息
export const REMOVE_USERDATA = 'REMOVE_USERDATA'
//编辑供应商信息
export const EDIT_SUPPLIER_INFO = 'EDIT_SUPPLIER_INFO'
//设置商家信息
export const SET_MERCHANT_INFO = 'SET_MERCHANT_INFO'
//移除商家信息
export const REMOVE_MERCHANT_INFO = 'REMOVE_MERCHANT_INFO'
