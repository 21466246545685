<template>
  <el-config-provider :locale="locale">
    <div id="app">
      <router-view/>
    </div>
  </el-config-provider>
</template>
<script>
import { defineComponent, onMounted, nextTick, ref } from "vue"
import { ElConfigProvider } from 'element-plus'
import zhLocale from 'element-plus/lib/locale/lang/zh-cn'
export default defineComponent({
  name:'app',
  components:{
    ElConfigProvider
  },
  setup(props) {
    const locale = ref(zhLocale);
    onMounted(() => {
      bodyScroll()
    })
    function bodyScroll(){
      nextTick(()=>{
        document.body.style.overflow = 'scroll'
      })
    }
    return{
      locale,
    }
  }
})
</script>
<style lang="scss">
#app{
  font-family: Avenir, Helvetica, Arial, sans-serif;
}  
</style>

<style media="print">
@page {
 margin:0;
}
</style>