import { createRouter, createWebHashHistory } from "vue-router";
import store from '@/store';

const routes = [{
    path: '/',
    name: '',
    redirect: '/cashier',
    meta:{
        permission: true
    }
}, {
    path: '/login',
    name: 'login',
    component: () => import ('@/views/login'),
    meta:{
        title:'登录',
        permission: true
    }
},{
    path: '/pages',
    name: 'pages',
    component: () => import ('@/views/pages/index'),
    children:[
        {
            path: '/cashier',
            name: 'cashier',
            component: () => import ('@/views/pages/cashier'),
            meta:{
                title:'台位',
                permission: false
            }
        }, {
            path: '/member',
            name: 'member',
            component: () => import ('@/views/pages/member'),
            meta:{
                title:'会员',
                permission: false
            }
        },  {
            path: '/pubDesk',
            name: 'pubDesk',
            component: () => import ('@/views/pages/pubDesk'),
            meta:{
                title:'收银',
                permission: false
            }
        }, {
            path: '/wineWarehouse',
            name: 'wineWarehouse',
            component: () => import ('@/views/pages/wineWarehouse'),
            meta:{
                title:'存酒管理',
                permission: false
            }
        // },{
        //     path: '/produce',
        //     name: 'produce',
        //     component: () => import ('@/views/pages/produce'),
        //     meta:{
        //         title:'出品',
        //         permission: false
        //     }
        },{
            path: '/notPermission',
            name: 'notPermission',
            component: () => import ('@/views/pages/notPermission'),
            meta:{
                title:'无权限',
                permission: true,
                hidden:true
            }
        }
    ]
},{
    path: '/:pathMatch(.*)*',
    name: 'noFound',
    component: () => import ('@/views/noFound'),
    meta:{
        title:'页面不存在',
        permission:true,
        hidden:true
    }
}]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

// 导航守卫
router.beforeEach((to, from, next) => {
    if(to.path == '/login'){
        next();
        return;
    }

    let all_available_menu = localStorage.getItem('all_available_menu')
    if(all_available_menu){
        all_available_menu = JSON.parse(all_available_menu)
    }
    if(all_available_menu && all_available_menu.length>0){
        for(let i in all_available_menu){
            if(all_available_menu[i].name === to.name){
                if(JSON.stringify(all_available_menu[i].meta) != '{}' && !all_available_menu[i].meta.permission){
                    next(`/notPermission`);
                    return;
                }
                break;
            }
        }
    }
    //非跳转到登录界面时，判断本地存储userData是否为null或空，为空则跳回到登录界面，反之到相应的界面(此时有数据)。
    let token = store.state.userData?store.state.userData.token:null;
    if (!token) {
        next(`/login?redirect=${to.path}`);
    } else {
        next();
    }
});


export default router