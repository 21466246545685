import * as types from './types'

export default{
    [types.SET_USERDATA]: (state, userData) => {
        state.userData = userData;
        localStorage.setItem('userData', JSON.stringify(userData));
    },
    [types.REMOVE_USERDATA]: (state) => {
        state.userData = null;
        localStorage.setItem('userData', null);
    },
    [types.EDIT_SUPPLIER_INFO]: (state,data) => {
        state.edit_supplier_info = data;
    },
    [types.SET_MERCHANT_INFO]: (state, merchant_info) => {
        state.merchant_info = merchant_info;
        localStorage.setItem('merchant_info', JSON.stringify(merchant_info));
    },
    [types.REMOVE_MERCHANT_INFO]: (state) => {
        state.merchant_info = null;
        localStorage.setItem('merchant_info', null);
    },
}